<template>
  <TModal
    title="Create"
    :show="show"
    @update:show="$emit('update:show', $event)"
    @click-create="create()"
    :key="key"
    :creating="disabled"
  >
    <TInputText label="Id" class="mb-3" @update:value="data.id = $event" />
    <TInputText label="Name" class="mb-3" @update:value="data.name = $event" />
  </TModal>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      data: {},
      key: "origin",
      disabled: false,
    };
  },
  created() {
    this.$store.dispatch("product.product_tags.fetch.if-first-time");
  },
  methods: {
    create() {
      this.disabled = true;
      this.$store
        .dispatch("product.product_tags.create", this.data)
        .then(() => {
          this.data = {};
          this.key = "" + this.lodash.random(10000, 100000);
          this.$emit("update:show", false);
        })
        .finally(() => {
          this.disabled = false;
        });
    },
  },
};
</script>
