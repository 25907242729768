<template>
  <div>
    <TTableAdvance
      :items="list"
      :fields="fields"
      store="product.product_tags"
      resource=""
      noFilter
      creatable
      deletable
      @click-create="showTagModal = true"
    >
      <template #name="{ item }">
        <td>
          <TMessageText
            :value="item.name"
            editable
            noTranslate
            @change="
              $store.dispatch('product.product_tags.detail.update', {
                name: $event,
              })
            "
          />
        </td>
      </template>
    </TTableAdvance>
    <TagModal :show.sync="showTagModal" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TagModal from "./components/TagModal.vue";

export default {
  components: { TagModal },
  data() {
    return {
      fields: [
        { key: "_", _style: "width: 40px" },
        { key: "id", label: "Id" },
        { key: "name", label: "Name", _style: "width: 50%" },
      ],
      showTagModal: false,
    };
  },
  created() {
    this.$store.dispatch("product.product_tags.fetch.if-first-time");
  },
  computed: {
    ...mapGetters({
      list: "product.product_tags.list",
    }),
  },
};
</script>
